import SVTeamFeedPage from '@apps/www/src/pages/team.page';
import SVSubscriptionExpiredNotice from '@apps/www/src/www/components/SVSubscriptionExpiredNotice';
import SVGridContainer from '@apps/www/src/www/containers/SVGridContainer';
import SVWithRequiredLogin from '@apps/www/src/www/containers/SVWithRequiredLogin';
import useAuthSubscriptionIsExpired from '@apps/www/src/www/hooks/useAuthSubscriptionIsExpired';
import useAuthTeam from '@apps/www/src/www/hooks/useAuthTeam';
import usePaginatedQuery from '@apps/www/src/www/hooks/usePaginatedQuery';
import FeedItemsQuery from '@apps/www/src/www/queries/FeedItemsQuery';
import { sendGTMEvent } from '@next/third-parties/google';
import SVFeedCTAs from '@pkgs/shared-client/components/SVFeedCTAs';
import SVGrid from '@pkgs/shared-client/components/SVGrid';
import UpgradeButtonSource from '@pkgs/shared/enums/UpgradeButtonSource';
import { useMount } from 'react-use';

const _FeedGrid = () => {
	const { items, loading, paginate } = usePaginatedQuery(FeedItemsQuery, {
		// No SSR here to speed up first load, feed is usually a slow query.
		ssr: false,
		// No need to check for errors here as this is the only page the user can load to access navigation
		errorPolicy: 'ignore',
	});

	return (
		<>
			<SVFeedCTAs />
			<SVGridContainer
				sourceType={SVGrid.SOURCE_TYPES.FEED}
				items={items}
				isLoading={loading}
				onPaginate={paginate}
			/>
		</>
	);
};

const _Feed = () => {
	const hasExpiredSubscription = Boolean(useAuthSubscriptionIsExpired());

	if (hasExpiredSubscription) {
		return <SVSubscriptionExpiredNotice source={UpgradeButtonSource.BLOCKED_FEED} />;
	}

	return <_FeedGrid />;
};

// forceRegularFeed is used in FeedPage to force the default feed query to be used
const _SVFeed = ({ forceRegularFeed }: { forceRegularFeed?: boolean }) => {
	const team = useAuthTeam();

	useMount(() => {
		sendGTMEvent('page_home_logged_in');
	});

	if (!(forceRegularFeed || !team?.teamFeedAsHomepage)) {
		return <SVTeamFeedPage />;
	}

	return <_Feed />;
};

const SVFeed = SVWithRequiredLogin(_SVFeed);

export default SVFeed;

/**
 * We display this message this to block the feed and search page when users have an expired subscription.
 */

import SVTrackedUpgradeButtonContainer from '@apps/www/src/www/containers/SVTrackedUpgradeButtonContainer';
import { SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import UpgradeButtonSource from '@pkgs/shared/enums/UpgradeButtonSource';

export default function SVSubscriptionExpiredNotice({
	source,
}: {
	source: ValueOf<typeof UpgradeButtonSource>;
}) {
	return (
		<div className="flex min-h-[calc(100vh-var(--nav-height)*2)] flex-col items-center justify-center">
			<div className="flex max-w-80 flex-col items-center justify-center space-y-4 text-center">
				<h1 className="type-small-title mb-4">Go back to Premium</h1>
				<p>Want to continue browsing ad-free inspiration curated by our&nbsp;team?</p>
				<p>Reactivate your subscription and continue getting&nbsp;inspired.</p>

				<div>
					<SVTrackedUpgradeButtonContainer
						className="mt-12"
						source={source}
						to="/upgrade/"
						use={SVButtonUSES.PRIMARY}
					>
						Let's go
					</SVTrackedUpgradeButtonContainer>
				</div>
			</div>
		</div>
	);
}
